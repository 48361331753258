
import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Avatar,
  Tooltip,
  Badge,
  Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Snackbar,
  Alert,
  CircularProgress,
} from '@mui/material';
import DriveFolderUploadRoundedIcon from '@mui/icons-material/DriveFolderUpload';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

function AccountPage({ onSubscriptionUpdate, subscriptionDetails }) {
  const [user, setUser] = useState(null);
  const [error, setError] = useState(null);
  const [supportDialogOpen, setSupportDialogOpen] = useState(false);
  const [confirmCancelDialogOpen, setConfirmCancelDialogOpen] = useState(false);
  const [confirmDeleteDialogOpen, setConfirmDeleteDialogOpen] = useState(false);
  const [issueDescription, setIssueDescription] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const navigate = useNavigate();
  const FREE_UPLOADS = 50;  //update it also in Sidebar.js and routes.py

  useEffect(() => {
    const token = localStorage.getItem('token');

    if (!token) {
      navigate('/');
      return;
    }

    const fetchAccountData = async () => {
      try {
        const response = await axios.get('https://api.idp.aiwizz.io/account', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setUser(response.data);
        onSubscriptionUpdate(response.data.subscription_details);
      } catch (err) {
        console.error('Error fetching account data:', err);
        setError('Failed to load account details.');
      }
    };

    fetchAccountData();
  }, [navigate, onSubscriptionUpdate]);

  const handleUpgrade = () => {
    navigate('/payment-setup', { state: { price_id: 'price_1QcuVPJeGVIgSUHZRVRySsLR' } });
  };

  const handleCancelSubscription = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.post(
        'https://api.idp.aiwizz.io/cancel-subscription',
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setSnackbarSeverity('success');
      setSnackbarMessage('Subscription canceled successfully.');
      setSnackbarOpen(true);

      const accountResponse = await axios.get('https://api.idp.aiwizz.io/account', {
        headers: { Authorization: `Bearer ${token}` },
      });
      setUser(accountResponse.data);

      if (response.data.subscription_details) {
        onSubscriptionUpdate(response.data.subscription_details);
      }
    } catch (error) {
      console.error('Error canceling subscription:', error);
      setSnackbarSeverity('error');
      setSnackbarMessage('Failed to cancel subscription.');
      setSnackbarOpen(true);
    } finally {
      setConfirmCancelDialogOpen(false);
    }
  };

  const handleDeleteAccount = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.delete('https://api.idp.aiwizz.io/delete-account', {
        headers: { Authorization: `Bearer ${token}` },
      });

      if (response.status === 200) {
        setSnackbarSeverity('success');
        setSnackbarMessage('Account deleted successfully.');
        setSnackbarOpen(true);

        // Keep the snackbar visible for 2 seconds, then redirect to login
        setTimeout(() => {
          localStorage.removeItem('token');
          navigate('/', { replace: true });
        }, 3000);
      }
    } catch (error) {
      console.error('Error deleting account:', error);
      setSnackbarSeverity('error');
      setSnackbarMessage('Failed to delete account.');
      setSnackbarOpen(true);
    } finally {
      setConfirmDeleteDialogOpen(false);
    }
  };

  const handleSupportDialogOpen = () => {
    setSupportDialogOpen(true);
  };

  const handleSupportDialogClose = () => {
    setSupportDialogOpen(false);
    setIssueDescription('');
  };

  const handleSupportSubmit = async () => {
    const token = localStorage.getItem('token');
    try {
      await axios.post(
        'https://api.idp.aiwizz.io/support',
        { issueDescription },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setSnackbarSeverity('success');
      setSnackbarMessage('Your issue has been submitted successfully.');
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Error submitting support issue:', error);
      setSnackbarSeverity('error');
      setSnackbarMessage('Failed to submit your issue.');
      setSnackbarOpen(true);
    } finally {
      handleSupportDialogClose();
    }
  };

  if (error) {
    return <Typography variant="h6">{error}</Typography>;
  }

  if (!user) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        height="100vh"
        p={3}
      >
        <CircularProgress />
        <Typography variant="h6" sx={{ mt: 2 }}>
          Loading account details...
        </Typography>
      </Box>
    );
  }

  const remainingUploads = Math.max(0, FREE_UPLOADS - user.request_count);

  const formatDate = (timestamp) => {
    if (!timestamp) return 'N/A';
    return new Date(timestamp * 1000).toLocaleDateString();
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      height="100vh"
      marginTop={-15}
      p={3}
    >
      <Avatar sx={{ width: 100, height: 100, mb: 2 }}>
        {user.fullname.charAt(0).toUpperCase()}
      </Avatar>
      <Typography variant="h4">{user.fullname}</Typography>
      <Typography variant="h6" color="textSecondary" marginBottom={5}>
        {user.email}
      </Typography>
      <Tooltip title="Number of Free Uploads Remaining">
        <Badge badgeContent={remainingUploads} color="primary" showZero>
          <DriveFolderUploadRoundedIcon fontSize="large" />
        </Badge>
      </Tooltip>
      <Typography variant="h6" color="textSecondary" marginTop={1}>
        Subscription Status: {user.subscription_status || 'Inactive'}
      </Typography>

      {subscriptionDetails && (
        <>
          <Typography variant="h6" color="textSecondary" marginTop={1}>
            Created: {formatDate(subscriptionDetails.created)}
          </Typography>
          <Typography variant="h6" color="textSecondary" marginTop={1}>
            Current Period: {formatDate(subscriptionDetails.current_period_start)} - {formatDate(subscriptionDetails.current_period_end)}
          </Typography>
          <Typography variant="h6" color="textSecondary" marginTop={1}>
            Next Invoice: {formatDate(subscriptionDetails.next_invoice)}
          </Typography>
        </>
      )}

      <Box display="flex" flexDirection="row" gap={2} sx={{ mt: 3 }}>
        {user.subscription_status !== 'active' && (
          <Button variant="contained" color="primary" onClick={handleUpgrade}>
            Upgrade to Paid Plan
          </Button>
        )}
        {user.subscription_status === 'active' && (
          <Button
            variant="contained"
            color="secondary"
            onClick={() => setConfirmCancelDialogOpen(true)}
          >
            Cancel Subscription
          </Button>
        )}
        <Button variant="outlined" color="secondary" onClick={handleSupportDialogOpen}>
          Support
        </Button>
        <Button
          variant="outlined"
          color="error"
          onClick={() => setConfirmDeleteDialogOpen(true)}
        >
          Delete Account
        </Button>
      </Box>

      <Dialog open={confirmCancelDialogOpen} onClose={() => setConfirmCancelDialogOpen(false)}>
        <DialogTitle>Confirm Cancellation</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to cancel your subscription? You will lose access to premium features at the end of the current billing period.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmCancelDialogOpen(false)}>No, Go Back</Button>
          <Button onClick={handleCancelSubscription} variant="contained" color="secondary">
            Yes, Cancel Subscription
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={confirmDeleteDialogOpen} onClose={() => setConfirmDeleteDialogOpen(false)}>
        <DialogTitle>Confirm Account Deletion</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to delete your account? This action is irreversible, and all your data will be permanently removed.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmDeleteDialogOpen(false)}>No, Go Back</Button>
          <Button onClick={handleDeleteAccount} variant="contained" color="error">
            Yes, Delete Account
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={supportDialogOpen} onClose={handleSupportDialogClose}>
        <DialogTitle>Support</DialogTitle>
        <DialogContent>
          <Typography variant="body1" gutterBottom>
            Please describe the issue you're experiencing:
          </Typography>
          <TextField
            autoFocus
            margin="dense"
            label="Issue Description"
            type="text"
            fullWidth
            multiline
            rows={4}
            value={issueDescription}
            onChange={(e) => setIssueDescription(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSupportDialogClose}>Cancel</Button>
          <Button
            onClick={handleSupportSubmit}
            variant="contained"
            color="primary"
            disabled={!issueDescription.trim()}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity={snackbarSeverity}
          sx={{ width: '100%' }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default AccountPage;