import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import axios from 'axios';
import { Box, Button, Typography, Paper } from '@mui/material';
import Payment from '@mui/icons-material/Payment';

function PaymentSetup() {
    const stripe = useStripe();
    const elements = useElements();
    const navigate = useNavigate();
    const location = useLocation();
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    // Get the price_id from the location state
    const price_id = location.state?.price_id;

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!stripe || !elements) {
            return;
        }

        setIsLoading(true);
        setError(null);

        const cardElement = elements.getElement(CardElement);

        // Create a payment method
        const { error: stripeError, paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement,
        });

        if (stripeError) {
            setError(stripeError.message);
            setIsLoading(false);
            return;
        }

        try {
            const token = localStorage.getItem('token');
            const response = await axios.post(
                'https://api.idp.aiwizz.io/save-payment-method',
                {
                    payment_method_id: paymentMethod.id,
                    price_id: price_id // Use the price_id from the location state
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            if (response.data.subscription_id) {
                // Subscription created successfully
                navigate('/account', { state: { subscriptionCreated: true } });
            } else {
                // Payment method saved successfully
                navigate('/account');
            }
        } catch (error) {
            console.error('Error saving payment method:', error);
            setError('Failed to save payment method.');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="80vh"
            bgcolor="#f5f5f5"
            p={2}
        >
            <Box>
                <Box marginBottom={6}>
                    <Payment color="success" fontSize="large" />
                    <Typography variant="h6" gutterBottom color={"green"}>
                        PLEASE ENTER YOUR PAYMENT METHOD BELOW.
                    </Typography>
                </Box>
                <Paper elevation={3} sx={{ p: 4, maxWidth: 500, width: '100%', maxHeight: 500, height: '100%' }}>
                    <Box mb={2} >
                        <Typography variant="h5" gutterBottom>
                            Enter Payment Method
                        </Typography>
                        <Typography variant="body1" gutterBottom color="textSecondary">
                            Please enter your payment details below to save them for future use. Payment is securely processed by Stripe.
                        </Typography>
                    </Box>
                    <form onSubmit={handleSubmit}>
                        <Box mb={3}>
                            <CardElement
                                options={{
                                    style: {
                                        base: {
                                            fontSize: '16px',
                                            color: '#424770',
                                            '::placeholder': {
                                                color: '#aab7c4',
                                            },
                                        },
                                        invalid: {
                                            color: '#9e2146',
                                        },
                                    },
                                }}
                            />
                        </Box>
                        <Button 
                            type="submit" 
                            variant="contained" 
                            color="primary" 
                            fullWidth
                            disabled={!stripe || isLoading}
                        >
                            {isLoading ? 'Processing...' : 'Save Payment Method'}
                        </Button>
                        {error && (
                            <Typography color="error" variant="body2" mt={2}>
                                {error}
                            </Typography>
                        )}
                    </form>
                </Paper>
            </Box>
        </Box>
    );
}

export default PaymentSetup;